import React from 'react';
import Slider from "react-slick"
import CoverageItem from './coverageItem/coverageItem'
import CustomCarouselArrow from '../customCarouselArrow/customCarouselArrow'

const PressCoverage = () => {
    const coverageItems = [  
        {
            id: '442',
            heading: `B2B marketplace Solv targets IPO, profitability by end of 2026`,
            content: `..............`,
            image: 'FE',
            publication: 'Financial Express',
            url: 'https://www.financialexpress.com/market/ipo-news-b2b-marketplace-solv-targets-ipo-profitability-by-end-of-2026-3667628/',
            postedDate: '18 November 2024'
        },
        {
            id: '441',
            heading: `B2B digital marketplace Solv plans IPO by 2026`,
            content: `..............`,
            image: 'FE',
            publication: 'Financial Express',
            url: 'https://clientportal.conceptbiu.com/mv/ad/10821-244164369-10821',
            postedDate: '18 November 2024'
        },
        {
            id: '43',
            heading: `How India’s MSMEs are building a future of growth and innovation`,
            content: `..............`,
            image: 'etEdge',
            publication: 'ET Edge Insights',
            url: 'https://etedge-insights.com/industry/manufacturing/how-indias-msmes-are-building-a-future-of-growth-and-innovation/',
            postedDate: '5 November 2024'
        },
        {
            id: '42',
            heading: `Breaking into Tier-2 and Tier-3 Markets: A Growth Strategy for Startups`,
            content: `..............`,
            image: 'startupTalky',
            publication: 'StartupTalky',
            url: 'https://startuptalky.com/tier-2-tier-3-startups-market-growth/',
            postedDate: '18 October 2024'
        },
        {
            id: '41',
            heading: `‘Asset light, tech heavy’ is how Solv India is redefining B2B commerce for MSMEs`,
            content: `..............`,
            image: 'ET',
            publication: 'Economic Times',
            url: 'https://economictimes.indiatimes.com/small-biz/sme-sector/asset-light-tech-heavy-is-how-solv-india-is-redefining-b2b-commerce-for-msmes/articleshow/113725803.cms',
            postedDate: '27 September 2024'
        },
        {
            id: '40',
            heading: `Bridging the gap between MSMEs and B2B commerce: How Solv is driving innovation under Amit Bansal’s leadership`,
            content: `..............`,
            image: 'ET',
            publication: 'Economic Times',
            url: 'https://economictimes.indiatimes.com/tech/technology/bridging-the-gap-between-msmes-and-b2b-commerce-how-solv-is-driving-innovation-under-amit-bansals-leadership/articleshow/113428321.cms',
            postedDate: '17 September 2024'
        },
        {
            id: '38',
            heading: `Logistics, water, culture: test your business creativity with Edition 146 of our weekly quiz!`,
            content: `..............`,
            image: 'yourStory',
            publication: 'Your Story',
            url: 'https://yourstory.com/2024/07/logistics-water-culture-business-creativity-entrepreneurship-opportunity-quiz-education',
            postedDate: '28 July 2024'
        },
        {
            id: '371',
            heading: `Comments of the ICT Industry Leaders on the Union Budget 2024-25`,
            content: `..............`,
            image: 'ncn',
            publication: 'National Computrade News',
            url: 'https://www.ncnonline.net/comments-of-the-ict-industry-leaders-on-the-union-budget-2024-25/',
            postedDate: '26 July 2024'
        },
        {
            id: '37',
            heading: `Mixed Reactions to FM Sitharaman’s Budget: Industry Praise Skilling & EPFO Reimbursements, Raise Concerns Over Tax Hikes`,
            content: `..............`,
            image: 'techgraph',
            publication: 'TechGraph',
            url: 'https://techgraph.co/budget-2024/mixed-reactions-fm-sitharaman-budget-industry-praise-skilling-epfo-reimbursements-raise-concerns-over-tax-hikes/',
            postedDate: '26 July 2024'
        },
        {
            id: '36',
            heading: `Post Budget Reaction from Mr. Amit Bansal, CEO, Solv.`,
            content: `.........................`,
            image: 'APN',
            publication: 'APN News',
            url: 'https://www.apnnews.com/post-budget-reaction-from-mr-amit-bansal-ceo-solv/',
            postedDate: '24 July 2024'
        },
        {
            id: '393',
            heading: `Startup Industry Experts Reacts to union Budget 2024`,
            content: `....................`,
            image: 'techiExpert',
            publication: 'Techie Expert',
            url: 'https://www.techiexpert.com/startup-industry-experts-reacts-to-union-budget-2024/',
            postedDate: '24 July 2024'
        },
        {
            id: '392',
            heading: `Government’s Budget Proposals Aim to Revitalize MSME Sector, Says Solv CEO`,
            content: `....................`,
            image: 'federalBharat',
            publication: 'Federal Bharat',
            url: 'https://federalbharat.com/governments-budget-proposals-aim-to-revitalize-msme-sector-says-solv-ceo/',
            postedDate: '23 July 2024'
        },
        {
            id: '391',
            heading: `Post Budget-2024 Quotes From Industry Leaders`,
            content: `....................`,
            image: 'cityAirNews',
            publication: 'City Air News',
            url: 'https://www.cityairnews.com/content/post-budget-2024-quotes-from-industry-leaders-6',
            postedDate: '23 July 2024'
        },
        {
            id: '39',
            heading: `Ecommerce operators hail TDS cut, export hubs announced in Budget 2024`,
            content: `....................`,
            image: 'yourStory',
            publication: 'Your Story',
            url: 'https://yourstory.com/2024/07/union-budget-ondc-amazon-meesho-flipkart-tds-cut-ecommerce-export-hub',
            postedDate: '23 July 2024'
        },
        {
            id: '34',
            heading: `Industry Leaders Share Insights: Post-Budget Reactions and Future Outlook.`,
            content: `..................`,
            image: 'CXO',
            publication: 'CXOtoday',
            url: 'https://cxotoday.com/specials/industry-leaders-share-insights-post-budget-reactions-and-future-outlook/',
            postedDate: '23 July 2024'
        },
        {
            id: '33',
            heading: `How Union Budget 2024 Sets the Stage for eCommerce and D2C Success`,
            content: `...............`,
            image: 'IR',
            publication: 'Indian Retailer',
            url: 'https://www.indianretailer.com/article/d2c-new-commerce/ecommerce/how-union-budget-2024-sets-stage-ecommerce-and-d2c-success',
            postedDate: '23 July 2024'
        },
        {
            id: '324',
            heading: `Union Budget Live Manufacturing MSMES and Tech Innovation In The Spotlight as Industry Experts Push for Key Reforms`,
            content: `...............`,
            image: 'techgraph',
            publication: 'Tech Graph',
            url: 'https://techgraph.co/budget-2024/union-budget-live-manufacturing-msmes-and-tech-innovation-in-the-spotlight-as-industry-experts-push-for-key-reforms/',
            postedDate: '23 July 2024'
        },
        {
            id: '323',
            heading: `Post Budget Reactions 2024-2025`,
            content: `...............`,
            image: 'theInfoEdge',
            publication: 'The Info Edge',
            url: 'https://theinfoedge.com/post-budget-reactions-2024-2025/',
            postedDate: '23 July 2024'
        },
        {
            id: '321',
            heading: `Industry Expectations from Budget 2024`,
            content: `...............`,
            image: 'smeTimes',
            publication: 'SME Times',
            url: 'https://www.smetimes.in/smetimes/news/top-stories/2024/Jul/19/budget-expectations-industry644663.html',
            postedDate: '19 July 2024'
        },
        {
            id: '32',
            heading: `Industries voice their budget desires: A look at sectoral expectations for Union Budget 2024-25`,
            content: `...............`,
            image: 'smeFutures',
            publication: 'SME Futures',
            url: 'https://smefutures.com/industries-voice-their-budget-desires-a-look-at-sectoral-expectations-for-union-budget-2024-25/',
            postedDate: '19 July 2024'
        },
        {
            id: '31',
            heading: `Voices Before the Verdict: Pre-Budget Quotes from India`,
            content: `......`,
            image: 'CXO',
            publication: 'CXOtoday',
            url: 'https://cxotoday.com/specials/voices-before-the-verdict-pre-budget-quotes-from-india/',
            postedDate: '18 July 2024'
        },
        {
            id: '21',
            heading: `Solv is addressing MSMEs' pain points by enabling market access and fair price.`,
            content: `Solv is a B2B ecommerce platform focused on MSMEs and has achieved $600 million of GMV while being profitable at the unit economics level.Despite these numbers and an increase in digital adoption in the country, MSMEs still find challenges in growing their business.The retail industry in India is estimated to be around $900 billion. Around 85% of the industry comes under the unorganised segment dominated by small retailers, while the rest comes from modern retail.`,
            image: 'yourStory',
            publication: 'Your Story',
            url: 'https://yourstory.com/smbstory/solv-is-addressing-msmes-pain-points-by-enabling-market-access-and-fair-price',
            postedDate: '15 July 2024'
        },
        {
            id: '22',
            heading: `Budget 2024: Credit, Skilling, 45-day payment rule, NPA norms among key asks from MSME sector`,
            content: `With the first full budget of the Modi 3.0 government around the corner, the MSME sector is hoping for a number of measures to be undertaken by the government to enhance their ease of doing business. The budget, to be presented on July 23 by Finance Minister Nirmala Sitharaman, is expected to address the issue of the revised 45-day payment rule introduced this financial year. Moreover, MSMEs are also looking at steps to boost credit access for them.`,
            image: 'FE',
            publication: 'Financial Express',
            url: 'https://www.financialexpress.com/budget/budget-2024-credit-skilling-45-day-payment-rule-npa-norms-among-key-asks-from-msme-sector-3552002/lite/',
            postedDate: '12 July 2024'
        },
        {
            id: '221',
            heading: `Budget-2024 7 key recommendations from MSME sector`,
            content: `.............................................`,
            image: 'FE',
            publication: 'Financial Express',
            url: 'https://www.financialexpress.com/web-stories/sme/budget-2024-7-key-recommendations-from-msme-sector/',
            postedDate: '12 July 2024'
        },
        {
            id: '23',
            heading: `Unlocking rural potential: How startups can turn rural businesses into economic powerhouses`,
            content: `While India Inc positively counts its GDP each time, there is a miniscule part that contributes to it—rural entrepreneurs.In recent years, rural entrepreneurship has emerged as a pivotal force driving economic growth and development in India, particularly the micro, small, and medium enterprises (MSMEs). This trend is more than just a wave of new businesses; it signifies a transformative shift towards harnessing local talent and resources to foster self-reliance and sustainable development in rural areas.`,
            image: 'smeFutures',
            publication: 'SME Futures',
            url: 'https://smefutures.com/unlocking-rural-potential-how-startups-can-turn-rural-businesses-into-economic-powerhouses/',
            postedDate: '9 July 2024'
        },
        {
            id: '1',
            heading: `Empowering India’s ‘Missing Middle’: How Solv is Transforming MSMEs and SMEs with Innovative B2B Solutions`,
            content: `At Solv we are building the Alibaba #2 from India. Solv is a B2B managed digital marketplace that connects factories to retailers. It enables them to solve the challenges associated with trade, access to credit, and other business-related services.`,
            image: 'CXO',
            publication: 'CXOtoday',
            url: 'https://cxotoday.com/interviews/empowering-indias-missing-middle-how-solv-is-transforming-msmes-and-smes-with-innovative-b2b-solutions/',
            postedDate: '17 June 2024'
        },
        {
            id: '2',
            heading: `The Critical role of product-market fit in startup success`,
            content: `The tech landscape of New India isn’t just about transactions; it’s an intertwining narrative of aspirations, change, and revolutionary ideas. While startups are the vessels of change, they are also stories of trials, tribulations, and triumphs.`,
            image: 'yourStory',
            publication: 'Your Story',
            url: 'https://yourstory.com/2023/12/critical-role-of-product-market-fit-in-startup-success',
            postedDate: '7 December 2023'
        },
        {
            id: '3',
            heading: `4 Tips To Have a Highly Productive and Successful Team at any Start-up`,
            content: `An organisational culture based on a strong set of values helps attract and retain talent, foster a positive and productive work environment, and provide a clear sense of direction for the company.`,
            image: 'entrepreneurMagazine',
            publication: 'Entrepreneur India',
            url: 'https://www.entrepreneur.com/en-in/news-and-trends/4-tips-to-have-a-highly-productive-and-successful-team-at/465120',
            postedDate: '9 November 2023'
        },
        {
            id: '4',
            heading: `Solv bags $40 MN in Series A`,
            content: `Bengaluru-based early-stage venture capital firm, Fundamental VC launches its maiden fund with a target corpus of $130 million. The fund received SEBI’s approval in March this year and has already started deploying capital with plans to lead investments in pre-seed and seed rounds.`,
            image: 'cnbc',
            publication: 'CNBC 18',
            url: 'https://www.cnbctv18.com/videos/startup/startup-street-key-concerns-of-msmes-in-india-fundamental-vcs-130-mn-maiden-fund-13953382.htm',
            postedDate: '27 June 2022'
        },
        {
            id: '5',
            heading: `B2B marketplace Solv raises $40 million in funding led by SBI Holdings`,
            content: `Solv, a marketplace for small businesses, has raised $40 million (around Rs 300 crore) in a round of funding led by Japan-headquartered SBI Holdings, with participation from SC Ventures, which has been an incubator and early-stage investor of the B2B digital marketplace. This round takes Solv’s total funding to nearly $80 million.`,
            image: 'ET',
            publication: 'Economic Times',
            url: 'https://economictimes.indiatimes.com/tech/funding/b2b-marketplace-solv-raises-40-million-in-funding-led-by-sbi-holdings/articleshow/92491337.cms',
            postedDate: '27 June 2022'
        },
        {
            id: '6',
            heading: `Solv raises $40 million in funding round led by Japan's SBI Holdings`,
            content: `Solv, a marketplace for small businesses, on Monday said it has raised USD 40 million (about Rs 310 crore) in funding led by Japan's SBI Holdings.`,
            image: 'BS',
            publication: 'Business Standard',
            url: 'https://www.business-standard.com/article/companies/solv-raises-40-million-in-funding-round-led-by-japan-s-sbi-holdings-122062700386_1.html',
            postedDate: '27 June 2022'
        },
        {
            id: '7',
            heading: `B2B digital marketplace Solv raises $40M in funding led by SBI Holdings`,
            content: `The stratup will use the fresh funds to launch additional high-margin product categories and expand to 300+ cities in India. The funding will also drive expansion of BNPL (Buy Now Pay Later) loans, particularly in the NTC (New-To-Credit) segment.`,
            image: 'yourStory',
            publication: 'Your Story',
            url: 'https://yourstory.com/2022/06/funding-b2b-digital-marketplace-solv-sbi-holdings/amp',
            postedDate: '27 June 2022'
        },
        {
            id: '8',
            heading: `B2B platform Solv raises $40 million in Series A round led by SBI Holdings`,
            content: `Solv clocked over $260 million GMV run-rate in the first half of 2022 with about 60 per cent average repeated purchases.`,
            image: 'HBL',
            publication: 'The Hindu Business Line',
            url: 'https://www.thehindubusinessline.com/companies/b2b-platform-solv-raises-40-million-in-series-a-round-led-by-sbi-holdings/article65569529.ece',
            postedDate: '27 June 2022'
        },
        {
            id: '9',
            heading: `Japan’s SBI Holdings bets on B2B MSME marketplace Solv`,
            content: `Solv, a business-to-business digital marketplace for micro, small and medium enterprises (MSMEs), has raised $40 million (Rs 300 crore) in its Series A funding round led by Japan’s SBI Holdings.`,
            image: 'vcCircle',
            publication: 'VC Circle',
            url: 'https://www.vccircle.com/japan-s-sbi-holdings-bets-on-b2b-msme-marketplace-solv',
            postedDate: '27 June 2022'
        },
        {
            id: '10',
            heading: `MSME marketplace Solv raises $40mn`,
            content: `Solv, a B2B digital marketplace for MSMEs, has raiseed $40 million in a funding round led by SBI Holding.`,
            image: 'TOI',
            publication: 'Times of India',
            url: 'https://timesofindia.indiatimes.com/city/bengaluru/msme-marketplace-solv-raises-40mn/articleshow/92507018.cms',
            postedDate: '27 June 2022'
        },
        {
            id: '11',
            heading: `Solv to deploy EVs for deliveries`,
            content: `Solv, the B2B e-commerce platform for MSMEs, announced that it will shift to electric vehicles (EVs) for its delivery services starting today. The initiative christened Solv EVolve expects 25 percent of all goods deliveries to MSMEs will be fulfilled within a year using EVs.`,
            image: 'FE',
            publication: 'Financial Express',
            url: 'https://www.financialexpress.com/express-mobility/vehicles/electric-vehicles/solv-to-deploy-evs-for-deliveries/2537672/',
            postedDate: '25 May 2022'
        },
        {
            id: '12',
            heading: `The Business secrets held in India's nutella jars`,
            content: `90% of the country’s trade passes through neighborhood 'kirana' shops, each with annual sales of $0.5 million or less.Their owners mostly operate without business registration and lack access to the formal banking system.`,
            image: 'mint',
            publication: 'Mint',
            url: 'https://www.livemint.com/companies/news/the-business-secrets-held-in-india-s-nutella-jars-11646005730728.html',
            postedDate: '28 Feb 2022'
        },
        {
            id: '13',
            heading: `The Business secrets held in India's nutella jars`,
            content: `90% of the country’s trade passes through neighborhood 'kirana' shops, each with annual sales of $0.5 million or less.Their owners mostly operate without business registration and lack access to the formal banking system.`,
            image: 'ET',
            publication: 'The Economic Times',
            url: 'https://m.economictimes.com/small-biz/sme-sector/the-business-secrets-held-in-indias-nutella-jars/articleshow/89883363.cms',
            postedDate: '28 Feb 2022'
        },
        {
            id: '14',
            heading: `The Business secrets held in India's nutella jars`,
            content: `Small retailers in India have always been neglected by banks. Standard Chartered is taking a granular approach to helping them get credit.`,
            image: 'bloomberg',
            publication: 'Bloomberg Opinion',
            url: 'https://www.bloomberg.com/opinion/articles/2022-02-27/standard-chartered-takes-a-granular-approach-to-small-business-credit-in-india?utm_medium=social&utm_source=twitter&utm_content=view&cmpid%3D=socialflow-twitter-view&utm_campaign=socialflow-organic',
            postedDate: '28 Feb 2022'
        }
    ]

    
  const coverageSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    accessibility: true,
    focusOnSelect: false,
    draggable: true,
    nextArrow: <CustomCarouselArrow type="Next" />,
    prevArrow: <CustomCarouselArrow type="Prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          accessibility: false,
          dots: false,
        },
      }
    ],
  }
    return (
        
        <Slider {...coverageSettings}>
            {coverageItems.map((post) => (
                <CoverageItem key={post.id} post={post} />
            ))}        
        </Slider>
    )
}

export default PressCoverage