import React from 'react';
import './commonCard.scss';
import '../../../styles.scss';
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import ReadMore from '../../../assets/read-more.png'

const CommonCard = ({ post }) => {

    function onClick(url) {
        window.open(url)
      }
    
    const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: DESC }
        filter: { relativeDirectory: { eq: "publications" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
    const logo = data.allFile.edges.find(ele =>
        ele.node.name.includes(post.image)
      )
    return (
        <div className='common-card-container'>
            <ImageDiv post={post} image={logo}/>
            <div className='card-heading'>{post.heading}</div>
            <div className='card-subHeading'>{post.subHeading}</div>
            <div className='card-read-more' onClick={() => onClick(post.url)}> <img src={ReadMore} alt="Read More Icon"/> READ MORE</div>
        </div>
    )
}

const ImageDiv = ({ post, image }) => (
    <div className='card-image-container'>
        <div className='card-post-image-section'>
            <Img
                key={image.node.id}
                fluid={image.node.childImageSharp.fluid}
                alt={`${post.publication}`}
            />
        </div>
        <div className='card-posted-date-section'>
            <span className='card-posted-date'>{post.postedDate}</span>
        </div>
    </div>
)

export default CommonCard